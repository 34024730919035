.overlay-container {
    position: relative;
}

.overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.overlay-container:hover .overlay-image {
    opacity: 0;
}
