* {
  font-family: 'Noto Sans JP', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.div1 {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5%;
}

.div2 {
  flex:70%;
  width: 50%; 
  margin: auto; 
}

.div3 {
  flex: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
}

.music-prompt {
  position: fixed;
  top: 0;
  scroll-margin-left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1vw;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999;
}

.prompt-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px; /* rounded corners */
  display: flex; /* added */
  flex-direction: column; /* added */
  align-items: center; /* added */
  justify-content: center; /* added */
}

.image-container { /* added this class */
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.prompt-image {
  display: flex;
  justify-content: space-between;
  width: 30%;
  cursor: pointer;
}


.desc {
  padding: 3vh;
  font-size: 1vw;
}

.auth {
  padding-top: 0vh;
  padding-left: 20vw;
  font-size: 1vw;
}


.music-player {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: 20px;
  padding-right: 20px;
  padding-left:20px;

}


.play-pause-image {
  cursor: pointer;
  width: 100px;
  height: 100px; /* Adjust the size of the image */
  order:1;
}

.volume-slider {
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  order:2;

}

.button-container {
  position: relative;
  width: 100%;
}

.galleryButtons {
  cursor: pointer;
  width: 10%;
  height: auto;
  position: absolute;
}

.left-button {
  left: 0;
}

.right-button {
  right: 0;
}